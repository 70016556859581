<template>
  <div
    v-if="$ability.can('read', this.$route.meta.resource)"
    class="p-2"
  >
    <b-tabs
      vertical
      card
    >
      <b-tab
        title="Android"
      >
        <Android />
      </b-tab>
      <b-tab title="iOS">
        <iOS />
      </b-tab>
    </b-tabs>
  </div>
</template>
<style>
.preview-img{
    /* width: 140px !important; */
    height: 500px;
    object-fit: cover;
}

.preview-imgs {
    width: 140px !important;
    height: 200px;
    object-fit: cover;
}
.btn-remove {
  position: absolute;
  top: 10%;
  right: 0px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 8px 8px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
</style>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Android from './components/Android.vue'
import iOS from './components/iOS.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    BTab,
    Android,
    iOS,
  },
  data() {
    return {
      isDragging: false,
      files: [],
    }
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files]
    },
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
  },
}
</script>
