<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-card>
      <b-tabs
        content-class="mt-3"
        justified
      >
        <b-tab
          :title="$t('6.7 Display')"
        >
          <b-card
            :title="$t('6.7 Display')"
            class="text-center"
            @dragover="dragover($event, 'phone6_7')"
            @dragleave="dragleave('phone6_7')"
            @drop="drop($event, 'phone6_7')"
          >
            <div
              class="dropzone-container"
            >
              <b-form-file
                id="filePhone6_7"
                ref="phone6_7"
                accept=".jpeg, .png"
                :hidden="true"
                name="phone6_7"
                plain
                @change="onChange($event,'phone6_7')"
              />
              <label
                @click="$refs.phone6_7.$el.click()"
              >
                <div v-if="phone6_7IsDragging">{{ $t('Release to drop files here') }}.</div>
                <div v-else>{{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u> {{ $t('to upload') }}.</div>
              </label>
            </div>
            <div
              v-if="phone6_7.length"
              class="preview-container"
            >
              <b-row>
                <b-col
                  v-for="(item, index) in phone6_7"
                  :key="index"
                  cols="2"
                >
                  <div>
                    <b-img
                      fluid-grow
                      :src="item"
                      rounded
                      class="mt-1 preview-imgs"
                      :alt="`phone6_7${index}`"
                    />
                    <b-button
                      variant="danger"
                      class="btn-remove"
                      @click="removeImage(index, 'phone6_7')"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>

                </b-col>
              </b-row>

            </div>
            <div class="mt-2 text-left">
              {{ phone6_7.length }}/8
            </div>
          </b-card>
          <div class="mb-2">
            อัปโหลดภาพหน้าจอโทรศัพท์ 3-8 ภาพ ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ JPEG ความละเอียด (1290 x 2796) หรือ (2796 x 1290) พิกเซล
          </div>
        </b-tab>
        <b-tab :title="$t('6.5 Display')">
          <b-card
            :title="$t('6.5 Display')"
            class="text-center"
            @dragover="dragover($event, 'phone6_5')"
            @dragleave="dragleave('phone6_5')"
            @drop="drop($event, 'phone6_5')"
          >
            <div
              class="dropzone-container"
            >
              <b-form-file
                id="filePhone6_5"
                ref="phone6_5"
                accept=".jpeg, .png"
                :hidden="true"
                name="phone6_5"
                plain
                @change="onChange($event,'phone6_5')"
              />
              <label
                @click="$refs.phone6_5.$el.click()"
              >
                <div v-if="phone6_5IsDragging">{{ $t('Release to drop files here') }}.</div>
                <div v-else>{{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u> {{ $t('to upload') }}.</div>
              </label>
            </div>
            <div
              v-if="phone6_5.length"
              class="preview-container"
            >
              <b-row>
                <b-col
                  v-for="(item, index) in phone6_5"
                  :key="index"
                  cols="2"
                >
                  <div>
                    <b-img
                      fluid-grow
                      :src="item"
                      rounded
                      class="mt-1 preview-imgs"
                      :alt="`phone6_5${index}`"
                    />
                    <b-button
                      variant="danger"
                      class="btn-remove"
                      @click="removeImage(index, 'phone6_5')"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>

                </b-col>
              </b-row>

            </div>
            <div class="mt-2 text-left">
              {{ phone6_5.length }}/8
            </div>
          </b-card>
          <div class="mb-2">
            อัปโหลดภาพหน้าจอโทรศัพท์ 3-8 ภาพ ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ JPEG ความละเอียด (1242 x 2688) หรือ (2688 x 1242) หรือ (1284 x 2778) หรือ (2778 x 1284) พิกเซล
          </div>
        </b-tab>
        <b-tab :title="$t('5.5 Display')">
          <b-card
            :title="$t('5.5 Display')"
            class="text-center"
            @dragover="dragover($event, 'phone5_5')"
            @dragleave="dragleave('phone5_5')"
            @drop="drop($event, 'phone5_5')"
          >
            <div
              class="dropzone-container"
            >
              <b-form-file
                id="filePhone5_5"
                ref="phone5_5"
                accept=".jpeg, .png"
                :hidden="true"
                name="phone5_5"
                plain
                @change="onChange($event,'phone5_5')"
              />
              <label
                @click="$refs.phone5_5.$el.click()"
              >
                <div v-if="phone5_5IsDragging">{{ $t('Release to drop files here') }}.</div>
                <div v-else>{{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u> {{ $t('to upload') }}.</div>
              </label>
            </div>
            <div
              v-if="phone5_5.length"
              class="preview-container"
            >
              <b-row>
                <b-col
                  v-for="(item, index) in phone5_5"
                  :key="index"
                  cols="2"
                >
                  <div>
                    <b-img
                      fluid-grow
                      :src="item"
                      rounded
                      class="mt-1 preview-imgs"
                      :alt="`phone5_5${index}`"
                    />
                    <b-button
                      variant="danger"
                      class="btn-remove"
                      @click="removeImage(index,'phone5_5')"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>

                </b-col>
              </b-row>

            </div>
            <div class="mt-2 text-left">
              {{ phone5_5.length }}/8
            </div>
          </b-card>
          <div class="mb-2">
            อัปโหลดภาพหน้าจอโทรศัพท์ 3-8 ภาพ ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ JPEG ความละเอียด (1242 x 2208) หรือ (2208 x 1242) พิกเซล
          </div>
        </b-tab>
        <b-tab :title="$t('12.9 Display')">
          <b-card
            :title="$t('12.9 Display')"
            class="text-center"
            @dragover="dragover($event, 'tablet12_9')"
            @dragleave="dragleave('tablet12_9')"
            @drop="drop($event, 'tablet12_9')"
          >
            <div
              class="dropzone-container"
            >
              <b-form-file
                id="fileTablet12_9"
                ref="tablet12_9"
                accept=".jpeg, .png"
                :hidden="true"
                name="tablet12_9"
                plain
                @change="onChange($event,'tablet12_9')"
              />
              <label
                @click="$refs.tablet12_9.$el.click()"
              >
                <div v-if="tablet12_9IsDragging">{{ $t('Release to drop files here') }}.</div>
                <div v-else>{{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u> {{ $t('to upload') }}.</div>
              </label>
            </div>
            <div
              v-if="tablet12_9.length"
              class="preview-container"
            >
              <b-row>
                <b-col
                  v-for="(item, index) in tablet12_9"
                  :key="index"
                  cols="2"
                >
                  <div>
                    <b-img
                      fluid-grow
                      :src="item"
                      rounded
                      class="mt-1 preview-imgs"
                      :alt="`tablet12_9${index}`"
                    />
                    <b-button
                      variant="danger"
                      class="btn-remove"
                      @click="removeImage(index,'tablet12_9')"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>

                </b-col>
              </b-row>

            </div>
            <div class="mt-2 text-left">
              {{ tablet12_9.length }}/8
            </div>
          </b-card>
          <div class="mb-2">
            อัปโหลดภาพหน้าจอแท็บเล็ตขนาด 12.9 นิ้ว 3-8 ภาพ ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ JPEG ความละเอียด (2048 x 2732) หรือ (2732 x 2048) พิกเซล
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-button
      size="sm"
      variant="primary"
      @click="saveData"
    >
      <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
      <feather-icon
        icon="SaveIcon"
        size="12"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol, BCard, BCardGroup, BFormFile, BCardText, BImg, BOverlay, BButton,
} from 'bootstrap-vue'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/services/hero/graphicsStore'

const STORE_MODULE_NAME = 'graphicsStore'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTabs, BTab, BRow, BCol, BCard, BCardGroup, BFormFile, BCardText, BImg, BOverlay, BButton,
  },
  data() {
    return {
      phone6_7IsDragging: false,
      phone6_5IsDragging: false,
      phone5_5IsDragging: false,
      tablet12_9IsDragging: false,
      phone6_7: [],
      phone6_5: [],
      phone5_5: [],
      tablet12_9: [],
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        const dataRes = result.data.data.ios
        this.phone6_7 = dataRes.mobile.size6_7
        this.phone6_5 = dataRes.mobile.size6_5
        this.phone5_5 = dataRes.mobile.size5_5
        this.tablet12_9 = dataRes.tablet.size12_9
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    saveData() {
      const obj = {
        os: 'ios',
        mobile: {
          size6_7: this.phone6_7,
          size6_5: this.phone6_5,
          size5_5: this.phone5_5,
        },
        tablet: {
          size12_9: this.tablet12_9,
        },
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/saveData`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
          const dataRes = result.data.data.ios
          this.phone6_7 = dataRes.mobile.size6_7
          this.phone6_5 = dataRes.mobile.size6_5
          this.phone5_5 = dataRes.mobile.size5_5
          this.tablet12_9 = dataRes.tablet.size12_9
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
          // this.showToast('success', 'bottom-right')
        }).catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    removeImage(index, type) {
      if (type === 'phone6_7') {
        this.phone6_7.splice(index, 1)
      } else if (type === 'phone6_5') {
        this.phone6_5.splice(index, 1)
      } else if (type === 'phone5_5') {
        this.phone5_5.splice(index, 1)
      } else if (type === 'tablet12_9') {
        this.tablet12_9.splice(index, 1)
      }
    },
    onChange(input, type) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          const image = new Image()
          image.src = e.target.result

          image.onload = () => {
            const { width, height } = image
            if (input.target.files[0].size > 2000000) {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: `${this.$t('Allowed')} JPEG ${this.$t('or')} PNG. ${this.$t('Max size of')} 2 MB`,
                },
              })
              return
            }
            if (type === 'phone6_7') {
              if ((width === 1290 && height === 2796) || (width === 2796 && height === 1290)) {
                if (this.phone6_7.length <= 8) {
                  this.phone6_7.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution (1290 x 2796) or (2796 x 1290) pixel',
                  },
                })
              }
            } else if (type === 'phone6_5') {
              if ((width === 1242 && height === 2688) || (width === 2688 && height === 1242) || (width === 1284 && height === 2778) || (width === 2778 && height === 1284)) {
                if (this.phone6_5.length <= 8) {
                  this.phone6_5.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution (1242 x 2688) or (2688 x 1242) or (1284 x 2778) or (2778 x 1284) pixel',
                  },
                })
              }
            } else if (type === 'phone5_5') {
              if ((width === 1242 && height === 2208) || (width === 2208 && height === 1242)) {
                if (this.phone5_5.length <= 8) {
                  this.phone5_5.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution (1242 x 2208) or (2208 x 1242) pixel',
                  },
                })
              }
            } else if (type === 'tablet12_9') {
              if ((width === 2048 && height === 2732) || (width === 2732 && height === 2048)) {
                if (this.tablet12_9.length <= 8) {
                  this.tablet12_9.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution (2048 x 2732) or (2732 x 2048) pixel',
                  },
                })
              }
            }
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    dragover(e, type) {
      e.preventDefault()
      if (type === 'phone6_7') {
        this.phone6_7IsDragging = true
      } else if (type === 'phone6_5') {
        this.phone6_5IsDragging = true
      } else if (type === 'phone5_5') {
        this.phone5_5IsDragging = true
      } else if (type === 'tablet12_9') {
        this.tablet12_9Dragging = true
      }
    },
    dragleave(type) {
      if (type === 'phone6_7') {
        this.phone6_7IsDragging = false
      } else if (type === 'phone6_5') {
        this.phone6_5IsDragging = false
      } else if (type === 'phone5_5') {
        this.phone5_5IsDragging = false
      } else if (type === 'tablet12_9') {
        this.tablet12_9Dragging = false
      }
    },
    drop(e, type) {
      e.preventDefault()
      console.log(e)
      console.log(type)
      this.onChange({ target: { files: e.dataTransfer.files } }, type)
      if (type === 'phone6_7') {
        this.phone6_7IsDragging = false
      } else if (type === 'phone6_5') {
        this.phone6_5IsDragging = false
      } else if (type === 'phone5_5') {
        this.phone5_5IsDragging = false
      } else if (type === 'tablet12_9') {
        this.tablet12_9Dragging = false
      }
    },
  },
}
</script>
